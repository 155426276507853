import { tint, shade, saturate, transparentize } from "polished";

export const ThemeName = {
  default: "default",
  light: "light",
  dark: "dark",
  contrast: "contrast"
};

const colorVariant = {
  /**
   * Temporary fix to complexity of applying colors
   * by checking what theme is being used. Design seems to stay
   * consistent with color contrast using a scale of 100 - 900
   * Hopefully in the future we can get a variant of these colors
   * for dark, light and constrast
   */
  [ThemeName.contrast]: {
    slate: {
      800: "#5F5E70"
    },
    ebony: {
      400: "#4A495E"
    }
  },
  [ThemeName.light]: {
    slate: {
      800: "#5F5E70"
    },
    ebony: {
      400: "#4A495E"
    }
  },
  [ThemeName.dark]: {
    slate: {
      800: "#5F5E70"
    },
    ebony: {
      400: "#4A495E"
    }
  }
};

const colorNames = {
  snow: "#ffffff",
  ice: "#f5f7fa",
  grey: "#f0f2f5",
  stone: "#e7e7e7",
  periwinkleGrey: "#c9d0e4",
  granite: "#98a0ab",
  slate: "#5f5e70",
  ebonyLight: "#4a495e",
  ebony: "#302f45",
  night: "#1a192b",
  skyeLight: "#e7f0ff",
  lighterSky: "#5792FF",
  skye: "#4786ff",
  skyeDark: "#1664ff",
  gold: "#eda900",
  pitch: "#23b7b7",
  fire: "#ff8359",
  red: "#fb3728",
  ocean: "#4c62a3",
  purple: "#9d67ff",
  wine: "#6d144f",
  pink: "#ffbdbb",
  lightPink: "#ffeeee",
  darkPink: "ff9f9f",
  hotPink: "#e04aa5",
  beige: "#f6f6f1",
  aqua: "#7bd8db",
  candyGreen: "#289b3f",
  wacgRed: "#e50000",
  lemon: "#fff9c4",
  oldGold: "#9a6f1e"
};

let themeOverrides = {
  accent: {
    light: colorNames.lightPink,
    medium: colorNames.pink
  },
  dark: colorNames.ebony,
  primary: colorNames.skye,
  buttons: colorNames.skye,
  links: colorNames.skye,
  success: colorNames.pitch,
  warning: colorNames.gold,
  failure: colorNames.fire,
  error: colorNames.red
};

const vendorOverrides = {
  ezcloud: {
    dark: "#302f45",
    primary: "#131C26",
    links: "#4786ff",
    success: "#00C89C",
    warning: "#F4BA37",
    failure: "#FF8359",
    error: "#FB3728",
    buttons: "#AE1164"
  },
  internal: {
    dark: "#444344",
    primary: "#145CC6",
    buttons: "#145CC6",
    links: "#145CC6",
    success: "#23B7B7",
    warning: "#FCC938",
    failure: "#FF7B22",
    error: "#DD5D05"
  },
  magento: {
    dark: "#373330",
    primary: "#66afe9",
    buttons: "#eb5202",
    links: "#007dbd",
    success: "#2cacb3",
    warning: "#ffb727",
    failure: "#ff6636"
  },
  favro: {
    dark: "#43326b",
    primary: "#2196f3",
    buttons: "#ea4c89",
    links: "#ea4c89",
    success: "#0097A7",
    warning: "#ffb727",
    failure: "#ff6636",
    error: "#ff0000"
  }
};

if (process.env.VENDOR_NAME) {
  themeOverrides = {
    ...themeOverrides,
    ...vendorOverrides[process.env.VENDOR_NAME],
    ...process.env.VENDOR_THEME
  };
}

// This is needed by the useTheme hook
export const palette = {
  ...colorNames,
  ...themeOverrides
};

const themes = {
  light: {
    name: "default",
    dark: themeOverrides.dark,
    primary: themeOverrides.primary,
    buttons: themeOverrides.buttons,
    links: themeOverrides.links,
    success: themeOverrides.success,
    warning: themeOverrides.warning,
    cancelled: colorNames.granite,
    failure: themeOverrides.failure,
    error: themeOverrides.error,
    background: colorNames.grey,
    sectionBg: colorNames.snow,
    text: colorNames.night,
    textLight: colorNames.ebonyLight,
    sectionHeading: colorNames.ebony,
    sectionText: colorNames.ebonyLight,
    sectionTextLight: colorNames.slate,
    sectionMoment: colorNames.slate,
    sectionBorder: "#d8e2f0",
    subsectionBorder: colorNames.stone,
    navText: colorNames.snow,
    buttonText: themeOverrides.buttonText || colorNames.snow,
    buttonCancelText: colorNames.slate,
    setupSidebar: saturate(0.07, shade(0.4, themeOverrides.dark)),
    setupSidebarActive: tint(0.13, themeOverrides.dark),
    setupSidebarActiveText: colorNames.snow,
    screenshotBg: colorNames.slate,
    screenshotTxt: colorNames.granite,
    liveBtn: colorNames.skyeLight,
    envTreeRootIcon: colorNames.snow,
    envTreeRootIconBg: themeOverrides.success,
    envTreeActiveIcon: colorNames.snow,
    envTreeInactiveIcon: colorNames.granite,
    envTreeInactiveIconBg: colorNames.grey,
    envTreeInactiveText: "#242428",
    activityHoverBg: colorNames.skyeLight,
    menuDropShadow: "0 6px 24px rgba(75, 97, 128, 0.32)",
    menuSearchBg: colorNames.ice,
    menuSearchIcon: "#32324c",
    dropDownBg: colorNames.snow,
    modalBg: colorNames.snow,
    modalOverlay: "rgba(56, 72, 94, 0.8)",
    preBg: themeOverrides.dark,
    preText: colorNames.snow,
    codeBg: "rgba(71,134,255,0.24)",
    codeText: saturate(1, shade(0.146, themeOverrides.primary)),
    subNavText: colorNames.ebonyLight,
    subNavActive: "#0e0d34",
    subNavTextRev: colorNames.granite,
    subNavActiveRev: colorNames.snow,
    sideNavText: "#38485e",
    sideNavBg: colorNames.snow,
    settingBarActive: colorNames.ice,
    settingBarLabel: colorNames.slate,
    settingShadow: "0 6px 24px rgba(152, 160, 171, 0.4)",
    inputText: colorNames.night,
    inputBg: colorNames.ice,
    inputActiveBorder: colorNames.granite,
    inputFocus:
      themeOverrides.inputFocus ||
      saturate(1, shade(0.146, themeOverrides.primary)),
    inputPlaceholder: colorNames.night,
    label: colorNames.slate,
    labelLight: colorNames.slate,
    environmentTreeLine: colorNames.periwinkleGrey,
    copyBg: colorNames.snow,
    descriptionText: "#4b6180",
    serviceTreeNode: colorNames.snow,
    serviceTreePaths: colorNames.grey,
    chevron: colorNames.granite,
    addFormShadow: "0 2px 6px rgba(152, 160, 171, 0.4)",
    serviceTreeNodeIconColor: colorNames.slate,
    wizardDropShadow: "0 6px 44px rgba(152, 160, 171, 0.6)",
    subtitleText: colorNames.granite,
    fieldError: themeOverrides.error,
    envLabel: colorNames.granite,
    bannerInfoColor: colorNames.wine,
    bannerWarningColor: themeOverrides.dark,
    bannerErrorColor: "#FFF",
    rowBackground: colorNames.snow,
    lineBackground: colorNames.snow,
    lineHoverBackground: colorNames.snow,
    bannerInfoBackgroundColor: themeOverrides.accent.light,
    bannerInfoBackgroundFocusColor: themeOverrides.accent.medium,
    tableBorder: colorNames.periwinkleGrey,
    orderInfo: colorNames.ebonyLight,
    orderInfoNumber: colorNames.ebony,
    orderBg: colorNames.snow,
    orderHeaderBg: colorNames.snow,
    orderHeaderOpenBg: colorNames.ice,
    orderTableHeader: colorNames.granite,
    orderTableBorder: colorNames.periwinkleGrey,
    orderSubtotalBg: colorNames.ice,
    orderTotalBg: colorNames.skyeLight,
    orderSummarySubtotalBg: colorNames.ice,
    orderSummaryTotalBg: colorNames.skyeLight,
    paymentCardSubtext: colorNames.granite,
    iconColor: colorNames.granite,
    iconHoverColor: colorNames.slate,
    focusColor: colorNames.skyeDark,
    focusShadow: `0px 0px 2px ${colorNames.skyeDark}, inset 0px 0px 2px ${
      colorNames.skyeDark
    }`,
    commitDate: colorNames.granite,
    bannerInlineErrorColor: colorNames.ebony,
    bannerInlineErrorBg: "#FCE1E1",
    bannerInlineInfoColor: colorNames.ebonyLight,
    bannerInlineLowColor: colorNames.ebonyLight,
    bannerInlineWarningColor: colorNames.ebonyLight,
    bannerInlineWarningBg: "#FCF4DB",
    bannerInlineWarningBorder: "#E1AD00",
    filePreviewerHeaderBg: colorNames.ebony,
    filePreviewerHeaderTitle: colorNames.snow,
    filePreviewerBtnIco: colorNames.granite,
    filePreviewerBtnIcoHover: colorNames.snow,
    filePreviewerBtnBgHover: colorNames.slate,
    filePreviewerBtnBgActive: colorNames.granite,
    secondaryBtnHoverBg: "#ededed",
    outlinedBtnBorder: colorNames.periwinkleGrey,
    outlinedBtnHover: colorNames.skyeDark,
    outlinedBtnBorderFocus: colorNames.skyeDark,
    buttonDisabled: colorNames.skyeLight,
    inputBorder: colorNames.granite,
    inputBorderHover: colorNames.skye,
    inputBorderFocus: colorNames.skyeDark,
    ideBg: colorNames.night,
    integrationTagColor: colorNames.skyeDark,
    integrationTagBg: "rgba(71, 134, 255, 0.24)",
    mobileMenuUserRow: colorNames.ebony,
    breadcrumbActive: colorNames.snow,
    breadcrumb: colorNames.periwinkleGrey,
    skeletonBackground: "rgb(228, 231, 241)",
    skeletonGradient:
      "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.96%, rgba(255, 255, 255, 0) 100%)",
    shadow: {
      large: "0px 6px 24px rgba(152, 160, 171, 0.4)"
    },
    badgeWarningBg: colorNames.lemon,
    badgeWarningText: colorNames.oldGold,
    modal: {
      background: colorNames.snow,
      contentBackgroundColor: colorNames.snow,
      overlayColor: transparentize(
        0.1,
        saturate(0.07, shade(0.4, colorNames.ebony))
      ),
      ...process.env.VENDOR_THEME?.[ThemeName.default || ThemeName.light]?.modal
    },
    logModal: {
      color: colorNames.snow,
      codeBackground: "inherit",
      background: colorNames.ebony,
      contentBackgroundColor: colorNames.snow,
      lineBackground: colorNames.ebonyLight,
      lineColor: colorNames.periwinkleGrey,
      lineSelectedBackground: "rgba(134, 181, 255, 0.3)",
      overlayColor: transparentize(
        0.1,
        saturate(0.07, shade(0.4, colorNames.ebony))
      ),
      ...process.env.VENDOR_THEME?.[ThemeName.default || ThemeName.light]
        ?.logModal
    },
    ...colorVariant[ThemeName.light],
    mobileNavTitle: colorNames.periwinkleGrey
  },
  contrast: {
    name: "contrast",
    dark: themeOverrides.dark,
    primary: saturate(1, shade(0.146, themeOverrides.primary)),
    buttons: saturate(1, shade(0.146, themeOverrides.buttons)),
    links: saturate(1, shade(0.146, themeOverrides.links)),
    success: themeOverrides.success,
    warning: saturate(0.05, shade(0.15, themeOverrides.warning)),
    cancelled: colorNames.granite,
    failure: themeOverrides.failure,
    error: saturate(0.12, shade(0.12, themeOverrides.error)),
    background: colorNames.grey,
    sectionBg: colorNames.snow,
    rowBackground: colorNames.snow,
    lineBackground: colorNames.snow,
    lineHoverBackground: colorNames.snow,
    text: colorNames.night,
    sectionHeading: colorNames.ebony,
    sectionText: "#38485e",
    textLight: colorNames.slate,
    sectionTextLight: colorNames.slate,
    sectionMoment: colorNames.slate,
    sectionBorder: "#d8e2f0",
    navText: colorNames.snow,
    buttonText: themeOverrides.buttonText || colorNames.snow,
    buttonCancelText: colorNames.ebonyLight,
    setupSidebar: saturate(0.07, shade(0.4, themeOverrides.dark)),
    setupSidebarActive: colorNames.snow,
    setupSidebarActiveText: colorNames.night,
    screenshotBg: colorNames.slate,
    screenshotTxt: colorNames.granite,
    envTreeRootIcon: colorNames.snow,
    envTreeRootIconBg: "#1fc79d",
    envTreeActiveIcon: colorNames.snow,
    envTreeInactiveIcon: tint(0.13, themeOverrides.dark),
    envTreeInactiveIconBg: colorNames.grey,
    envTreeInactiveText: "#242428",
    menuDropShadow: "0 6px 24px rgba(75, 97, 128, 0.32)",
    menuSearchBg: colorNames.ice,
    menuSearchIcon: "#32324c",
    dropDownBg: colorNames.snow,
    modalBg: colorNames.snow,
    modalOverlay: "rgba(56, 72, 94, 0.8)",
    preBg: themeOverrides.dark,
    preText: colorNames.snow,
    codeBg: "#e7f0ff",
    codeText: "#0a5cff",
    subNavText: colorNames.ebonyLight,
    subNavActive: colorNames.ebony,
    subNavTextRev: colorNames.granite,
    subNavActiveRev: colorNames.snow,
    sideNavText: "#38485e",
    sideNavBg: colorNames.snow,
    settingBarActive: colorNames.ice,
    settingBarLabel: colorNames.slate,
    inputText: colorNames.night,
    inputBg: colorNames.ice,
    inputActiveBorder: colorNames.granite,
    inputFocus:
      themeOverrides.inputFocus ||
      saturate(1, shade(0.146, themeOverrides.primary)),
    label: colorNames.slate,
    labelLight: colorNames.slate,
    environmentTreeLine: colorNames.periwinkleGrey,
    copyBg: colorNames.snow,
    descriptionText: "#4b6180",
    serviceTreeNode: colorNames.snow,
    serviceTreePaths: "#f0f0f0",
    chevron: colorNames.ebonyLight,
    addFormShadow: "0 2px 6px rgba(152, 160, 171, 0.4)",
    serviceTreeNodeIconColor: colorNames.slate,
    wizardDropShadow: "0 6px 44px rgba(152, 160, 171, 0.6)",
    subtitleText: colorNames.ebonyLight,
    fieldError: themeOverrides.error,
    envLabel: colorNames.ebonyLight,
    bannerInfoColor: colorNames.wine,
    bannerWarningColor: themeOverrides.dark,
    bannerErrorColor: "#FFF",
    bannerInfoBackgroundColor: colorNames.lightPink,
    tableBorder: colorNames.periwinkleGrey,
    bannerInlineInfoColor: colorNames.ebonyLight,
    bannerInlineLowColor: colorNames.ebonyLight,
    bannerInlineWarningColor: colorNames.snow,
    secondaryBtnHoverBg: "#ededed",
    outlinedBtnBorder: colorNames.periwinkleGrey,
    outlinedBtnHover: colorNames.skyeDark,
    outlinedBtnBorderFocus: colorNames.skyeDark,
    buttonDisabled: colorNames.skyeLight,
    inputBorder: colorNames.granite,
    ideBg: colorNames.night,
    integrationTagColor: colorNames.skyeDark,
    integrationTagBg: "rgba(71, 134, 255, 0.24)",
    inputBorderHover: colorNames.skye,
    inputBorderFocus: colorNames.skyeDark,
    mobileMenuUserRow: "#01001A",
    breadcrumbActive: colorNames.snow,
    breadcrumb: colorNames.periwinkleGrey,
    skeletonBackground: "rgb(71, 71, 89)",
    skeletonGradient:
      "linear-gradient(90deg, rgba(48, 47, 69, 0) 0%, #302F45 48.96%, rgba(48, 47, 69, 0) 100%)",
    shadow: {
      large: "0px 6px 24px rgba(152, 160, 171, 0.4)"
    },
    modal: {
      background: colorNames.snow, // colorNames.ebony,
      contentBackgroundColor: colorNames.snow,
      overlayColor: transparentize(
        0.1,
        saturate(0.07, shade(0.4, colorNames.ebony))
      ),
      ...process.env.VENDOR_THEME?.[ThemeName.contrast]?.modal
    },
    logModal: {
      color: colorNames.snow,
      codeBackground: "inherit",
      background: colorNames.night,
      contentBackgroundColor: colorNames.snow,
      overlayColor: transparentize(
        0.1,
        saturate(0.07, shade(0.4, colorNames.ebony))
      ),
      ...process.env.VENDOR_THEME?.[ThemeName.contrast]?.logModal
    },
    ...colorVariant[ThemeName.contrast],
    mobileNavTitle: colorNames.periwinkleGrey
  },
  dark: {
    name: "dark",
    dark: themeOverrides.dark,
    primary: themeOverrides.primary,
    buttons: themeOverrides.buttons,
    buttonBorder: colorNames.slate,
    links: themeOverrides.links,
    success: themeOverrides.success,
    warning: themeOverrides.warning,
    cancelled: colorNames.granite,
    failure: themeOverrides.failure,
    error: themeOverrides.error,
    background: saturate(0.07, shade(0.4, themeOverrides.dark)),
    sectionBg: "#232235",
    rowBackground: transparentize(0.6, themeOverrides.dark),
    lineBackground: transparentize(0.4, themeOverrides.dark),
    lineHoverBackground: transparentize(0, themeOverrides.dark),
    text: colorNames.snow,
    sectionHeading: colorNames.snow,
    sectionText: colorNames.snow,
    textLight: colorNames.periwinkleGrey,
    sectionTextLight: colorNames.periwinkleGrey,
    sectionMoment: colorNames.granite,
    sectionBorder: tint(0.13, themeOverrides.dark),
    subsectionBorder: colorNames.ebonyLight,
    navText: colorNames.snow,
    buttonText: themeOverrides.buttonText || colorNames.snow,
    buttonCancelText: colorNames.periwinkleGrey,
    setupSidebar: saturate(0.07, shade(0.4, themeOverrides.dark)),
    setupSidebarActive: tint(0.13, themeOverrides.dark),
    setupSidebarActiveText: colorNames.snow,
    screenshotBg: colorNames.slate,
    screenshotTxt: colorNames.granite,
    liveBtn: colorNames.skyeLight,
    envTreeRootIcon: themeOverrides.success,
    envTreeRootIconBg: tint(0.13, themeOverrides.dark),
    envTreeActiveIcon: themeOverrides.primary,
    envTreeInactiveIcon: colorNames.periwinkleGrey,
    envTreeInactiveIconBg: tint(0.13, themeOverrides.dark),
    envTreeInactiveText: colorNames.granite,
    activityHoverBg: themeOverrides.dark,
    menuDropShadow: "0px 6px 44px #01001a",
    menuSearchBg: tint(0.13, themeOverrides.dark),
    menuSearchIcon: colorNames.periwinkleGrey,
    dropDownBg: saturate(0.03, shade(0.2, themeOverrides.dark)),
    modalBg: themeOverrides.dark,
    modalOverlay: "rgba(26, 25, 43, 0.9)",
    preBg: colorNames.ebony,
    preText: colorNames.snow,
    codeBg: tint(0.13, themeOverrides.dark),
    codeText: colorNames.periwinkleGrey,
    subNavText: colorNames.granite,
    subNavActive: colorNames.snow,
    sideNavText: colorNames.granite,
    sideNavBg: themeOverrides.dark,
    settingBarActive: "rgba(26, 25, 43, 0.5)",
    settingBarLabel: colorNames.granite,
    settingShadow: "0px 6px 44px #01001A",
    inputText: colorNames.snow,
    inputBg: saturate(0.07, shade(0.4, themeOverrides.dark)),
    inputActiveBorder: tint(0.13, themeOverrides.dark),
    inputFocus:
      themeOverrides.inputFocus ||
      saturate(1, shade(0.146, themeOverrides.primary)),
    inputPlaceholder: colorNames.granite,
    label: colorNames.periwinkleGrey,
    labelLight: colorNames.snow,
    environmentTreeLine: tint(0.13, themeOverrides.dark),
    copyBg: tint(0.13, themeOverrides.dark),
    descriptionText: colorNames.snow,
    serviceTreeNode: colorNames.snow,
    serviceTreePaths: tint(0.13, themeOverrides.dark),
    chevron: colorNames.periwinkleGrey,
    addFormShadow: "0 2px 6px rgba(26, 25, 43, 0.4)",
    serviceTreeNodeIconColor: colorNames.slate,
    wizardDropShadow: "0 6px 44px rgba(26, 25, 43, 0.6)",
    subtitleText: colorNames.granite,
    fieldError: themeOverrides.error,
    envLabel: colorNames.granite,
    bannerInfoColor: colorNames.wine,
    bannerWarningColor: themeOverrides.dark,
    bannerErrorColor: "#FFF",
    bannerInfoBackgroundColor: colorNames.lightPink,
    tableBorder: tint(0.13, themeOverrides.dark),
    orderInfo: colorNames.snow,
    orderInfoNumber: colorNames.snow,
    orderBg: colorNames.ebony,
    orderHeaderBg: "#252438",
    orderHeaderOpenBg: "#252438",
    orderTableHeader: colorNames.granite,
    orderTableBorder: colorNames.ebonyLight,
    orderSubtotalBg: colorNames.slate,
    orderTotalBg: "#3c3c4d",
    orderBgAlt: transparentize(0.5, colorNames.night),
    orderSummarySubtotalBg: colorNames.night,
    orderSummaryTotalBg: colorNames.skye,
    paymentCardSubtext: colorNames.snow,
    iconHoverColor: colorNames.periwinkleGrey,
    bannerInlineInfoColor: colorNames.ebonyLight,
    bannerInlineLowColor: colorNames.ebonyLight,
    bannerInlineWarningColor: colorNames.snow,
    secondaryBtnHoverBg: colorNames.ebonyLight,
    outlinedBtnBorder: colorNames.ebonyLight,
    outlinedBtnHover: colorNames.lighterSky,
    outlinedBtnBorderFocus: "#0A5CFF",
    buttonDisabled: colorNames.lightBlue,
    inputBorder: colorNames.slate,
    inputBorderHover: colorNames.lighterSky,
    inputBorderFocus: colorNames.skyeDark,
    filePreviewerHeaderBg: colorNames.ebony,
    filePreviewerHeaderTitle: colorNames.snow,
    filePreviewerBtnIco: colorNames.granite,
    filePreviewerBtnIcoHover: colorNames.snow,
    filePreviewerBtnBgHover: colorNames.slate,
    filePreviewerBtnBgActive: colorNames.granite,
    ideBg: colorNames.night,
    integrationTagColor: colorNames.periwinkleGrey,
    integrationTagBg: colorNames.ebonyLight,
    mobileMenuUserRow: colorNames.ebony,
    breadcrumbActive: colorNames.snow,
    breadcrumb: colorNames.periwinkleGrey,
    skeletonBackground: "rgb(71, 71, 89)",
    skeletonGradient:
      "linear-gradient(90deg, rgba(48, 47, 69, 0) 0%, #302F45 48.96%, rgba(48, 47, 69, 0) 100%)",
    shadow: {
      large: "rgb(1 0 26) 0px 6px 44px"
    },
    modal: {
      background: colorNames.ebony,
      contentBackgroundColor: colorNames.snow,
      overlayColor: transparentize(
        0.1,
        saturate(0.07, shade(0.4, colorNames.ebony))
      ),
      ...process.env.VENDOR_THEME?.[ThemeName.dark]?.modal
    },
    logModal: {
      color: colorNames.snow,
      codeBackground: "inherit",
      background: colorNames.night,
      contentBackgroundColor: colorNames.snow,
      overlayColor: transparentize(
        0.1,
        saturate(0.07, shade(0.4, colorNames.ebony))
      ),
      ...process.env.VENDOR_THEME?.[ThemeName.dark]?.logModal
    },
    ...colorVariant[ThemeName.dark],
    mobileNavTitle: colorNames.periwinkleGrey
  }
};

export default themes;
